<template>
  <div class="heavyItems">
    <!-- 搜索筛选 -->
    <el-form :inline="true" :model="formInline" class="form-search">
      <el-form-item label="品类\品种\品名">
        <el-cascader
          v-model="cascaderValue"
          placeholder="请选择"
          :props="categoryCascadeProps"
          clearable
          @change="categoryChange"
        />
      </el-form-item>
      <!-- <el-form-item label="品类">
        <el-select
          size="small"
          @change="getChildNode(1, formInline.categoryId)"
          v-model="formInline.categoryId"
          placeholder="请选"
        >
          <el-option label="全部" value></el-option>
          <el-option
            v-for="(item, index) in varietyData1"
            :key="index"
            :label="item.goodsName"
            :value="item.code"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="品种">
        <el-select
          size="small"
          @change="getChildNode(2, formInline.varietyId)"
          v-model="formInline.varietyId"
          placeholder="请选择"
        >
          <el-option label="全部" value></el-option>
          <el-option
            v-for="(item, index) in varietyData2"
            :key="index"
            :label="item.goodsName"
            :value="item.code"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="品名">
        <el-select
          @change="associativeSearch"
          size="small"
          v-model="formInline.goodsId"
          placeholder="请选择"
        >
          <el-option label="全部" value></el-option>
          <el-option
            v-for="(item, index) in varietyData3"
            :key="index"
            :label="item.goodsName"
            :value="item.code"
          ></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="材质">
        <el-select v-model="formInline.materials" size="small" placeholder="选择完品名才会出现材质">
          <el-option label="全部" value />
          <el-option
            v-for="(item, index) in varietyData4"
            :key="index"
            :label="item.materials"
            :value="item.materials"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="型号">
        <el-select v-model="formInline.specs" size="small" placeholder="选择完品名才会出现型号">
          <el-option label="全部" value />
          <el-option
            v-for="(item, index) in varietyData5"
            :key="index"
            :label="item.specs"
            :value="item.specs"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="产地">
        <el-select
          v-model="formInline.productions"
          filterable
          remote
          size="small"
          reserve-keyword
          placeholder="请输入关键字搜索"
          :remote-method="remoteMethod"
          @focus="remoteMethod"
        >
          <el-option label="全部" value />
          <el-option
            v-for="item in restaurants"
            :key="item.id"
            :label="item.brandProduction"
            :value="item.brandProduction"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" @click="getdata">
          搜索
        </el-button>
        <el-button size="small" type="primary" @click="resetFormInline">
          重置
        </el-button>
      </el-form-item>
    </el-form>
    <!--列表-->
    <div class="page-container-table">
      <div class="list-operation">
        <el-button size="small" type="primary" @click="editAndAddRow">
          添加
        </el-button>
      </div>
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      />
      <!-- 分页组件 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
    <!-- 编辑and新增弹出层 -->
    <el-dialog
      :title="title"
      :visible.sync="editFormVisible"
      :close-on-click-modal="false"
      width="40%"
      @click="editFormVisible=false"
    >
      <el-form ref="editForm" :model="editFormData" class="from" label-position="left" label-width="120px" :rules="rules">
        <el-form-item label="品类(一级)" prop="categoryId">
          <el-select
            v-model="editFormData.categoryId"
            size="small"
            placeholder="请选择"
            @change="getChildNode(1, editFormData.categoryId, 123)"
          >
            <el-option
              v-for="(item, index) in editNeedFormData.varietyData1"
              :key="index"
              :label="item.goodsName"
              :value="item.code"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="品种(二级)" prop="varietyId">
          <el-select
            v-model="editFormData.varietyId"
            size="small"
            placeholder="请先选择品类"
            @change="getChildNode(2, editFormData.varietyId, 123)"
          >
            <el-option
              v-for="(item, index) in editNeedFormData.varietyData2"
              :key="index"
              :label="item.goodsName"
              :value="item.code"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="品名(三级)" prop="goodsId">
          <el-select
            v-model="editFormData.goodsId"
            size="small"
            placeholder="请先选择品种"
            @change="associativeSearch2"
          >
            <el-option
              v-for="(item, index) in editNeedFormData.varietyData3"
              :key="index"
              :label="item.goodsName"
              :value="item.code"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="材质" prop="materials">
          <el-select v-model="editFormData.materials" size="small" placeholder="请先选择品名">
            <el-option
              v-for="(item, index) in editNeedFormData.varietyData4"
              :key="index"
              :label="item.materials"
              :value="item.materials"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="型号" prop="specs">
          <el-select v-model="editFormData.specs" size="small" placeholder="请先选择品名">
            <el-option
              v-for="(item, index) in editNeedFormData.varietyData5"
              :key="index"
              :label="item.specs"
              :value="item.specs"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="产地" prop="productions">
          <el-select
            v-model="editFormData.productions"
            filterable
            remote
            size="small"
            reserve-keyword
            placeholder="请输入"
            :remote-method="remoteMethod"
            @focus="remoteMethod"
          >
            <el-option
              v-for="item in restaurants"
              :key="item.id"
              :label="item.brandProduction"
              :value="item.brandProduction"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="件重(吨)" prop="weights">
          <el-input
            v-model="editFormData.weights"
            size="small"
            auto-complete="off"
            placeholder="请输入件重"
            type="number"
          />
        </el-form-item>
        <el-form-item label="长度(米)">
          <el-input
            v-model="editFormData.length"
            size="small"
            auto-complete="off"
            placeholder="请输入长度"
            type="number"
          />
        </el-form-item>
        <el-form-item label="描述" prop="remark">
          <el-input
            v-model="editFormData.remark"
            size="small"
            auto-complete="off"
            placeholder="请输入描述"
          />
        </el-form-item>
        <el-form-item label="状态" prop="state">
          <el-radio-group v-model="editFormData.state">
            <el-radio :label="1">
              有效
            </el-radio>
            <el-radio :label="0">
              无效
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="form-operation">
          <el-button size="small" plain class="widen-button" @click="editFormVisible=false">
            取消
          </el-button>
          <el-button
            size="small"
            type="primary"
            :loading="loading"
            class="title widen-button"
            @click="submitForm('editForm')"
          >
            保存
          </el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import Table from '../../components/Table'
import Pagination from '../../components/Pagination2'
import { goodsProductionStartOrDisableOrDelete, addAndEditGoodsWeights, getGoodsWeightsPage, getGoodsSpecsList, getGoodsProductionPage, getChildGoodsList, getGoodsMaterialsList } from '../../api/goods'
export default {
  // 注册组件
  components: {
    Pagination,
    Table
  },
  data() {
    return {
      editFormVisible: false,
      editFormData: {
        categoryId: '',
        varietyId: '',
        goodsId: '',
        materials: ''
      },
      title: '添加',
      itemData: [
        {
          label: '件重(吨)',
          prop: 'weights'
        },
        {
          label: '品类(一级)',
          prop: 'categoryName'
        },
        {
          label: '品种(二级)',
          prop: 'varietyName'
        }, {
          label: '品名(三级)',
          prop: 'goodsName'
        },
        {
          label: '型号',
          prop: 'specs'
        },
        {
          label: '材质',
          prop: 'materials'
        },
        {
          label: '产地',
          prop: 'productions'
        },
        {
          label: '长度(米)',
          prop: 'length'
        },
        {
          label: '状态',
          prop: 'state',
          child: [{ value: 1, label: '有效' }, { value: '0', label: '无效' }]
        }
      ],
      operationButton: [
        { bType: 'primary', label: '编辑', handleEvent: this.editAndAddRow },
        { withParameters: 2, bType: 'danger', label: '删除', handleEvent: this.disableEnabledRow },
        { withParameters: 1, num: 0, val: 'state', bType: 'success', label: '启用', handleEvent: this.disableEnabledRow },
        { withParameters: 0, num: 1, val: 'state', bType: 'danger', label: '禁用', handleEvent: this.disableEnabledRow }
      ],
      loading: false,
      pinzhong: '',
      rules: {
        weights: [{ required: true, message: '请输入数字', trigger: 'blur' }],
        categoryId: [{ required: true, message: '请选择品类', trigger: 'change' }],
        varietyId: [{ required: true, message: '请选择品种', trigger: 'change' }],
        goodsId: [{ required: true, message: '请选择品名', trigger: 'change' }],
        materials: [{ required: true, message: '请选择材质', trigger: 'change' }],
        specs: [{ required: true, message: '请选择规格型号', trigger: 'change' }],
        productions: [{ required: true, message: '请选择产地', trigger: 'change' }],
        state: [{ required: true, message: '请选择状态', trigger: 'change' }]
      },
      listData: [],
      varietyData1: [],
      varietyData2: [],
      varietyData3: [],
      varietyData4: [],
      varietyData5: [],
      editNeedFormData: {
        varietyData1: [],
        varietyData2: [],
        varietyData3: [],
        varietyData4: [],
        varietyData5: []
      },
      formInline: {
        pageSize: 10,
        pageNum: 1
      },
      data: [],
      restaurants: [],
      total: 0,
      cascaderValue: '',
      // 划级联渲染规则
      categoryCascadeProps: {
        lazy: true,
        lazyLoad: this.categoryCascaderLazyLoad,
        value: 'code',
        label: 'goodsName'
      }
    }
  },
  activated() {
    this.getdata()
  },
  created() {
    this.getdata()
    this.getChildNode()
  },
  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {
    // 材质跟型号的搜索
    associativeSearch() {
      if (!this.formInline.goodsId) return
      getGoodsMaterialsList({ state: 1, categoryId: this.formInline.categoryId, varietyId: this.formInline.varietyId, goodsId: this.formInline.goodsId }, res => {
        this.varietyData4 = [...res.data]
      })
      getGoodsSpecsList({ categoryId: this.formInline.categoryId, varietyId: this.formInline.varietyId, goodsId: this.formInline.goodsId }, res => {
        this.varietyData5 = [...res.data]
      })
    },
    associativeSearch2() {
      // if (!this.editFormData.goodsId) return
      getGoodsMaterialsList({ state: 1, categoryId: this.editFormData.categoryId, varietyId: this.editFormData.varietyId, goodsId: this.editFormData.goodsId }, res => {
        this.editNeedFormData.varietyData4 = [...res.data]
      })
      getGoodsSpecsList({ categoryId: this.editFormData.categoryId, varietyId: this.editFormData.varietyId, goodsId: this.editFormData.goodsId }, res => {
        this.editNeedFormData.varietyData5 = [...res.data]
      })
    },
    // 产地的联动狂
    remoteMethod(queryString) {
      const value = queryString instanceof Object ? '' : queryString
      getGoodsProductionPage({ pageNum: 1, pageSize: 1000, brandProduction: value || '' }, res => {
        this.restaurants = res.data.pageData
      })
    },
    // 修改和编辑
    editAndAddRow(row) {
      this.editFormVisible = true
      if (row.id) {
        // 把下拉框的值带上
        this.associativeSearch2()
        this.getChildNode(1, row.categoryId, 12)
        this.getChildNode(2, row.varietyId, 12)
        this.editFormData = { ...row }
        this.title = '编辑'
        return
      }
      this.editFormData = {
        categoryId: '',
        varietyId: '',
        goodsId: '',
        materials: ''
      }
      this.title = '添加'
      this.editNeedFormData.varietyData2 = []
      this.editNeedFormData.varietyData3 = []
      this.editNeedFormData.varietyData4 = []
      this.editNeedFormData.varietyData5 = []
      this.$refs['editForm'].resetFields()
    },
    // 点击新增或者修改的确认按钮
    submitForm(editData) {
      this.$refs[editData].validate(valid => {
        if (valid) {
          this.loading = true
          this.editNeedFormData.varietyData3.forEach(val => {
            if (val.code === this.editFormData.goodsId) { this.editFormData.goodsName = val.goodsName }
          })

          addAndEditGoodsWeights(this.editFormData, () => {
            this.loading = false
            this.editFormVisible = false
            this.$message.success('成功！')
            this.getdata(true)
          }, () => {
            this.loading = false
          })
        }
      })
    },
    // 获取数据
    getdata(type) {
      if (!type) {
        this.formInline.pageNum = 1
      }
      getGoodsWeightsPage(this.formInline, res => {
        this.listData = [...res.data.pageData].map((item) => {
          if (item.weights)item.weights = item.weights.toFixed(2)
          return { ...item }
        })
        this.total = res.data.total
      }, () => {
        this.total = 0
        this.listData = []
      })
    },
    // 禁用或者启用
    disableEnabledRow(row, state) {
      this.$confirm('此操作将改变或删除状态, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        goodsProductionStartOrDisableOrDelete({ id: row.id, state }, () => {
          this.$message.success('成功')
          this.getdata()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 级联选择器懒加载
    categoryCascaderLazyLoad(node, resolve) {
      const { level, value: code = '' } = node
      const params = level === 0 ? '' : code
      getChildGoodsList(params, res => {
        const data = res.data.map(item => {
          return {
            leaf: level >= 2,
            ...item
          }
        })
        resolve([...data])
      })
    },
    // 级联change事件
    categoryChange(val) {
      if (val.length === 0 || !val) {
        this.varietyData4 = this.varietyData5 = []
        return
      }
      this.formInline.categoryId = val[0]
      this.formInline.varietyId = val[1]
      this.formInline.goodsId = val[2]
      this.associativeSearch()
    },
    // 根据父节点获取下面的子节点 edit判断是弹出的框
    getChildNode(type, pId, edit) {
      getChildGoodsList(pId || '', res => {
        res.data.forEach((item, index) => {
          if (item.state === 0)res.data.splice(index, 1)
        })
        // 这里是弹出层联动执行
        if (edit) {
          switch (type) {
            case 1:
              this.editNeedFormData.varietyData2 = [...res.data]
              if (edit !== 12) {
                this.editFormData.goodsId = ''
                this.editFormData.varietyId = ''
                this.editNeedFormData.varietyData3 = []
              }
              break
            case 2:
              if (edit !== 12) this.editFormData.goodsId = ''
              this.editNeedFormData.varietyData3 = [...res.data]
              break
          }
          this.$forceUpdate()
          return
        }
        // 下面是搜索框联动执行
        switch (type) {
          case 1:
            this.varietyData2 = [...res.data]
            this.formInline.goodsId = ''
            this.formInline.varietyId = ''
            this.varietyData3 = []
            break
          case 2:
            this.formInline.goodsId = ''
            this.varietyData3 = [...res.data]
            break
          default:
            this.varietyData1 = [...res.data]
            this.editNeedFormData.varietyData1 = [...res.data]
            break
        }
      })
    },
    // 重置
    resetFormInline() {
      this.formInline = {
        pageSize: 10,
        pageNum: 1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.contentArea {
  display: flex;
  margin-top: 20px;
  overflow-x: hidden;
}
.contentArea .left {
  flex: 0 0 200px;
  padding-right: 12px;
  overflow-x: auto;
}
.contentArea .right {
  width: calc(100% - 212px);
}
.form-search {
  margin-top: 20px;
  padding-left: 16px;
}
.from .el-select,
.from .el-input {
  width: 100%;
}
.userRole {
  width: 100%;
}
.buttonCombination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 42px;
  font-size: 14px;
  border: 1px solid #eee;
  border-bottom: 0;
  padding: 0 16px;
  .el-button{
    margin-top: 12px;
  }
}
</style>

